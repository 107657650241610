import { SearchParameters } from 'src/Utilities/HelperClass'
import { searchParametersActionTypes } from '../ActionTypes'

const initialState = {
  searchParameters: [],
  fields: {},
  current: null,
}

const searchParameterReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchParametersActionTypes.ADD_SEARCH_PARAMETER:
        let item = state.searchParameters.filter(x=> x.identityKey === action.identityKey).at(0);
        if(item === undefined){
            let sp = new SearchParameters(action.key, action.values, action.operator, action.identityKey)
            state.searchParameters.push(sp);
        }
        else{
            item.key = action.key ? item.key : action.key;
            item.values = action.values ? item.values : action.values;
            item.operator = action.operator ? item.operator : action.operator;
        }
        state.current = action.identityKey
      return {
        ...state,
      }
    case searchParametersActionTypes.CLEAR_SEARCH_PARAMETER:
        state.searchParameters = []
        state.current = identityKey
        return {
            ...state
        }
    case searchParametersActionTypes.SET_FIELD_VALUE:
        state.fields[action.fieldName] = action.fieldValue;
        state.current = action.identityKey
        return { ...state }
    case searchParametersActionTypes.GET_FIELD_VALUE:
            return state.fields[action.fieldName];
    default:
      return state
  }
}

export { searchParameterReducer }
