import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { newLetterActionTypes, commonActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
  ...defaultProperties,
}

const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case newLetterActionTypes.FETCH_NEWS_LETTER_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case newLetterActionTypes.FETCH_NEWS_LETTER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case newLetterActionTypes.FETCH_NEWS_LETTER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case newLetterActionTypes.DELETE_NEWS_LETTER_REQUEST:
      return {
        ...state,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
        loading: true,
      }
    case newLetterActionTypes.DELETE_NEWS_LETTER_REQUEST_SUCCESS:
      return {
        ...state,
        addedItem: null,
        deletedItem: true,
        updatedItem: null,
        loading: false,
        data: data.filter((x) => x.id !== action.payload.id),
      }
    case newLetterActionTypes.DELETE_NEWS_LETTER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        addedItem: null,
        deletedItem: false,
        updatedItem: null,
      }
    case newLetterActionTypes.ADD_NEWS_LETTER_REQUEST:
      return {
        ...state,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
        loading: true,
      }
    case newLetterActionTypes.ADD_NEWS_LETTER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedItem: true,
        deletedItem: null,
        updatedItem: null,
        data: [...state.data, action.payload],
      }
    case newLetterActionTypes.ADD_NEWS_LETTER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        addedItem: false,
        deletedItem: null,
        updatedItem: null,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.NEWS_LETTER):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { newsletterReducer }
