import { formCommonSearchFilterAction } from 'src/Utilities'
import { APP_SETTINGS } from 'src/Utilities/Constants'
import { REDUCERS } from '..'
import { commonActionTypes, contactActionTypes } from '../ActionTypes'

const initialState = {
    loading: false,
    orderBy: APP_SETTINGS.DEFAULT_SORT_FIELD,
    sortDirection: APP_SETTINGS.DEFAULT_SORT_DIRECTION,
    pageIndex: APP_SETTINGS.DEFAULT_PAGE_INDEX,
    pageSize: APP_SETTINGS.DEFAULT_PAGE_SIZE,
    searchKey: '',
    error: '',
    searchParameters: [],
    data: [],
    viewList: []
}

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case contactActionTypes.FETCH_CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
                orderBy: action.payload.orderBy,
                sortDirection: action.payload.sortDirection,
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                searchKey: action.payload.searchKey,
                addedItem: null,
                deletedItem: null,
                updatedItem: null,
            }
        case contactActionTypes.FETCH_CONTACT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case contactActionTypes.FETCH_CONTACT_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }
        case contactActionTypes.DELETE_CONTACT_REQUEST:
            return {
                ...state,
                addedItem: null,
                deletedItem: null,
                updatedItem: null,
                loading: true,
            }
        case contactActionTypes.DELETE_CONTACT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                deletedItem: true,
                addedItem: null,
                updatedItem: null,
                message: action.payload,
            }
        case contactActionTypes.DELETE_CONTACT_REQUEST_ERROR:
            return {
                ...state,
                addedItem: null,
                deletedItem: false,
                updatedItem: null,
                loading: false,
            }
        case contactActionTypes.FETCH_CONTACT_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case contactActionTypes.FETCH_CONTACT_BY_ID_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                viewList: action.payload,
            }
        case contactActionTypes.FETCH_CONTACT_BY_ID_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                viewList: [],
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        case formCommonSearchFilterAction(REDUCERS.CONTACT):
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export { contactReducer }
