import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage/session'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import {
  serverErrorReducer,
  exceptionReducer,
  accountReducer,
  visitorReducer,
  VisitorCountReducer,
  PageVisitorCountReducer,
  emailReducer,
  qeReducer,
  newsletterReducer, lookupReducer, contactReducer, consultReducer,
  linkClickReducer,
} from './Redux/Reducer'
import {REDUCERS} from './Redux'
import { ENVIRONMENT } from './Utilities/Constants'
import { userReducer } from './Redux/Reducer/userReducer'
import { searchParameterReducer } from './Redux/Reducer/searchParameterReducer'
import { dailyVisitor, PageVisitorData, weeklyVisitorReducer } from './Redux/Reducer/VisitorsReducer'
import { galleryReducer } from './Redux/Reducer/galleryReducer'
import { processListReducer, StatusReducer } from './Redux/Reducer/commonReducer'
import { fileReducer } from './Redux/Reducer/fileReducer'
import { countReducer } from './Redux/Reducer/countReducer'
import { jobExecutionReducer } from './Redux/Reducer/jobExecutionReducer'
import { rbacReducer } from './Redux/Reducer/rbacReducer'
import { offerReducer } from './Redux/Reducer/offerReducer'
import { columnDefinitionReducer } from './Redux/Reducer/columnDefinitionReducer'
import { lookupsReducer } from './Redux/Reducer/lookupReducer'
const persistConfig = {
  key: 'root',
  storage,
}
const initialState = {
  sidebarShow: true,
}
const changeState = (state = initialState, { type, ...rest } = null) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'unset':
      return { ...state, ...rest, add: true }
    default:
      return state
  }
}
const reducers = {}
reducers[REDUCERS.ACCOUNT] = accountReducer;
reducers[REDUCERS.VISITOR] = visitorReducer;
reducers[REDUCERS.OFFERS] = offerReducer;
reducers[REDUCERS.WEEKLY_VISITOR] = weeklyVisitorReducer;
reducers[REDUCERS.USER] = userReducer;
reducers[REDUCERS.SERVER_ERROR] = serverErrorReducer;
reducers[REDUCERS.VISITOR_COUNT] = VisitorCountReducer;
reducers[REDUCERS.PAGE_WISE_COUNT] = PageVisitorCountReducer;
reducers[REDUCERS.EMAIL] = emailReducer;
reducers[REDUCERS.ACCOUNT] = accountReducer;
reducers[REDUCERS.EXCEPTION] = exceptionReducer;
reducers[REDUCERS.NEWS_LETTER] = newsletterReducer;
reducers[REDUCERS.GALLERY] = galleryReducer;
reducers[REDUCERS.PROCESSES] = processListReducer;
reducers[REDUCERS.STATUS] = StatusReducer;
reducers[REDUCERS.IP_INFO] = dailyVisitor;
reducers[REDUCERS.LOOKUP] = lookupsReducer;
reducers[REDUCERS.LOOKUPS] = lookupReducer;
reducers[REDUCERS.CONSULT] = consultReducer;
reducers[REDUCERS.CONTACT] = contactReducer;
reducers[REDUCERS.QA_REDUCER] = qeReducer;
reducers[REDUCERS.JOB_EXECUTION] = jobExecutionReducer;
reducers[REDUCERS.COUNTER] = countReducer;
reducers[REDUCERS.RBAC] = rbacReducer;
reducers[REDUCERS.LINK_CLICK] = linkClickReducer;
reducers[REDUCERS.PAGE_VISITED] = PageVisitorData;
reducers[REDUCERS.COLUMN_DEFINITION] = columnDefinitionReducer;
reducers[REDUCERS.SEARCH_PARAM] = searchParameterReducer;
reducers[REDUCERS.CHANGE_STATE] = changeState;
reducers[REDUCERS.FILE_INFO] = fileReducer;


const combineReducer = combineReducers(reducers);

// const store = createStore(combineReducer, composeEnhancer(applyMiddleware(thunk)))
export const store = configureStore({
  reducer: persistReducer(persistConfig, combineReducer),
  devTools: process.env.NODE_ENV !== ENVIRONMENT.PRODUCTION,
  middleware: [thunk],
})
export const persistStorage = storage
export const persistor = persistStore(store)
