const commonActionTypes = {
  EXPORT_REQUEST_ERROR: 'EXPORT_REQUEST_ERROR',
  EXPORT_REQUEST_SUCCESS: 'EXPORT_REQUEST_SUCCESS',
  EXPORT_REQUEST: 'EXPORT_REQUEST',

  LIST_PROCESS_REQUEST: 'LIST_PROCESS_REQUEST',
  LIST_PROCESS_REQUEST_SUCCESS: 'LIST_PROCESS_REQUEST_SUCCESS',
  LIST_PROCESS_REQUEST_ERROR: 'LIST_PROCESS_REQUEST_ERROR',

  KILL_PROCESS_REQUEST: 'KILL_PROCESS_REQUEST',
  KILL_PROCESS_REQUEST_SUCCESS: 'KILL_PROCESS_REQUEST_SUCCESS',
  KILL_PROCESS_REQUEST_ERROR: 'KILL_PROCESS_REQUEST_ERROR',

  FETCH_STATUS_REQUEST: 'FETCH_STATUS_REQUEST',
  FETCH_STATUS_REQUEST_SUCCESS: 'FETCH_STATUS_REQUEST_SUCCESS',
  FETCH_STATUS_REQUEST_ERROR: 'FETCH_STATUS_REQUEST_ERROR',

  RESET_REDUCERS: 'RESET_REDUCERS',

  FETCH_LOOKUP_REQUEST: 'FETCH_LOOKUP_REQUEST',
  FETCH_LOOKUP_REQUEST_SUCCESS: 'FETCH_LOOKUP_REQUEST_SUCCESS',
  FETCH_LOOKUP_REQUEST_FAILURE: 'FETCH_LOOKUP_REQUEST_FAILURE',

  FETCH_COLUMN_DEFINITION_LOOKUP_REQUEST: 'FETCH_COLUMN_DEFINITION_LOOKUP_REQUEST',
  FETCH_COLUMN_DEFINITION_REQUEST_SUCCESS: 'FETCH_COLUMN_DEFINITION_REQUEST_SUCCESS',
  FETCH_COLUMN_DEFINITION_FAILURE: 'FETCH_COLUMN_DEFINITION_FAILURE',

  UPDATE_FILTERS: 'UPDATE_FILTER_',
  UPDATE_AUTO_REFRESH: 'UPDATE_AUTO_REFRESH_'
}
const adminDashBoardActionType = {
  // fetch actions
  FETCH_VISITORS_REQUEST: 'FETCH_VISITORS_REQUEST',
  FETCH_VISITORS_REQUEST_SUCCESS: 'FETCH_VISITORS_REQUEST_SUCCESS',
  FETCH_VISITORS_REQUEST_ERROR: 'FETCH_VISITORS_REQUEST_ERROR',

  FETCH_WEEKLY_VISITORS_REQUEST: 'FETCH_WEEKLY_VISITORS_REQUEST',
  FETCH_WEEKLY_VISITORS_REQUEST_SUCCESS: 'FETCH_WEEKLY_VISITORS_REQUEST_SUCCESS',
  FETCH_WEEKLY_VISITORS_REQUEST_ERROR: 'FETCH_WEEKLY_VISITORS_REQUEST_ERROR',

  FETCH_DASHBOARD_COUNT_REQUEST: 'FETCH_DASHBOARD_COUNT_REQUEST',
  FETCH_DASHBOARD_COUNT_REQUEST_SUCCESS: 'FETCH_DASHBOARD_COUNT_REQUEST_SUCCESS',
  FETCH_DASHBOARD_COUNT_REQUEST_ERROR: 'FETCH_DASHBOARD_COUNT_REQUEST_ERROR',

  FETCH_PAGE_WISE_COUNT_REQUEST: 'FETCH_PAGE_WISE_COUNT_REQUEST',
  FETCH_PAGE_WISE_COUNT_REQUEST_SUCCESS: 'FETCH_PAGE_WISE_COUNT_REQUEST_SUCCESS',
  FETCH_PAGE_WISE_COUNT_REQUEST_ERROR: 'FETCH_PAGE_WISE_COUNT_REQUEST_ERROR',

  // export actions
  EXPORT_VISITORS_REQUEST: 'EXPORT_VISITORS_REQUEST',
  EXPORT_VISITORS_REQUEST_SUCCESS: 'EXPORT_VISITORS_REQUEST_SUCCESS',
  EXPORT_VISITORS_REQUEST_ERROR: 'EXPORT_VISITORS_REQUEST_ERROR',

  EXPORT_DASHBOARD_COUNT_REQUEST: 'EXPORT_DASHBOARD_COUNT_REQUEST',
  EXPORT_DASHBOARD_COUNT_REQUEST_SUCCESS: 'EXPORT_DASHBOARD_COUNT_REQUEST_SUCCESS',
  EXPORT_DASHBOARD_COUNT_REQUEST_ERROR: 'EXPORT_DASHBOARD_COUNT_REQUEST_ERROR',

  EXPORT_PAGE_WISE_COUNT_REQUEST: 'EXPORT_PAGE_WISE_COUNT_REQUEST',
  EXPORT_PAGE_WISE_COUNT_REQUEST_SUCCESS: 'EXPORT_PAGE_WISE_COUNT_REQUEST_SUCCESS',
  EXPORT_PAGE_WISE_COUNT_REQUEST_ERROR: 'EXPORT_PAGE_WISE_COUNT_REQUEST_ERROR',

}

const loginActions = {
  AUTHENTICATE_USER_REQUEST: 'AUTHENTICATE_USER_REQUEST',
  AUTHENTICATE_USER_SUCCESS: 'AUTHENTICATE_USER_SUCCESS',
  AUTHENTICATE_USER_FAILED: 'AUTHENTICATE_USER_FAILED',
  LOG_OUT_REQUEST: 'LOG_OUT_REQUEST',
  UPDATE_ACCESS_TOKEN: 'UPDATE_ACCESS_TOKEN',

  // refresh token
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR'
}

const serverErrors = {
  SET_SERVER_ERROR: 'SET_SERVER_ERROR',
  RESET_SERVER_ERROR: 'RESET_SERVER_ERROR',
  GET_SERVER_ERROR: 'GET_SERVER_ERROR',
}

const exceptionActionTypes = {
  FETCH_EXCEPTION_REQUEST: 'FETCH_EXCEPTION_REQUEST',
  FETCH_EXCEPTION_REQUEST_SUCCESS: 'FETCH_EXCEPTION_REQUEST_SUCCESS',
  FETCH_EXCEPTION_REQUEST_ERROR: 'FETCH_EXCEPTION_REQUEST_ERROR',
  DELETE_EXCEPTION_REQUEST: 'DELETE_EXCEPTION_REQUEST',
  DELETE_EXCEPTION_REQUEST_SUCCESS: 'DELETE_EXCEPTION_REQUEST_SUCCESS',
  DELETE_EXCEPTION_REQUEST_ERROR: 'DELETE_EXCEPTION_REQUEST_ERROR',
  EXPORT_EXCEPTION_REQUEST_ERROR: 'EXPORT_EXCEPTION_REQUEST_ERROR',
  EXPORT_EXCEPTION_REQUEST_SUCCESS: 'EXPORT_EXCEPTION_REQUEST_SUCCESS',
  EXPORT_EXCEPTION_REQUEST: 'EXPORT_EXCEPTION_REQUEST',
}

const emailActionTypes = {
  ADD_EMAIL_REQUEST: 'ADD_EMAIL_REQUEST',
  ADD_EMAIL_REQUEST_SUCCESS: 'ADD_EMAIL_REQUEST_SUCCESS',
  ADD_EMAIL_REQUEST_ERROR: 'ADD_EMAIL_REQUEST_ERROR',

  FETCH_EMAIL_REQUEST: 'FETCH_EMAIL_REQUEST',
  FETCH_EMAIL_REQUEST_SUCCESS: 'FETCH_EMAIL_REQUEST_SUCCESS',
  FETCH_EMAIL_REQUEST_ERROR: 'FETCH_EMAIL_REQUEST_ERROR',

  FETCH_EMAIL_BY_ID_REQUEST: 'FETCH_EMAIL_BY_ID_REQUEST',
  FETCH_EMAIL_BY_ID_REQUEST_SUCCESS: 'FETCH_EMAIL_BY_ID_REQUEST_SUCCESS',
  FETCH_EMAIL_BY_ID_REQUEST_ERROR: 'FETCH_EMAIL_BY_ID_REQUEST_ERROR',

  RESEND_EMAIL_BY_ID_REQUEST: 'RESEND_EMAIL_BY_ID_REQUEST',
  RESEND_EMAIL_BY_ID_REQUEST_SUCCESS: 'RESEND_EMAIL_BY_ID_REQUEST_SUCCESS',
  RESEND_EMAIL_BY_ID_REQUEST_ERROR: 'RESEND_EMAIL_BY_ID_REQUEST_ERROR',

  DELETE_EMAIL_REQUEST: 'DELETE_EMAIL_REQUEST',
  DELETE_EMAIL_REQUEST_SUCCESS: 'DELETE_EMAIL_REQUEST_SUCCESS',
  DELETE_EMAIL_REQUEST_ERROR: 'DELETE_EMAIL_REQUEST_ERROR',

  EXPORT_EMAIL_REQUEST_ERROR: 'EXPORT_EMAIL_REQUEST_ERROR',
  EXPORT_EMAIL_REQUEST_SUCCESS: 'EXPORT_EMAIL_REQUEST_SUCCESS',
  EXPORT_EMAIL_REQUEST: 'EXPORT_EMAIL_REQUEST',
}

const contactActionTypes = {
  FETCH_CONTACT_REQUEST: 'FETCH_CONTACT_REQUEST',
  FETCH_CONTACT_REQUEST_SUCCESS: 'FETCH_CONTACT_REQUEST_SUCCESS',
  FETCH_CONTACT_REQUEST_ERROR: 'FETCH_CONTACT_REQUEST_ERROR',

  FETCH_CONTACT_BY_ID_REQUEST: 'FETCH_CONTACT_BY_ID_REQUEST',
  FETCH_CONTACT_BY_ID_REQUEST_SUCCESS: 'FETCH_CONTACT_BY_ID_REQUEST_SUCCESS',
  FETCH_CONTACT_BY_ID_REQUEST_ERROR: 'FETCH_CONTACT_BY_ID_REQUEST_ERROR',

  DELETE_CONTACT_REQUEST: 'DELETE_CONTACT_REQUEST',
  DELETE_CONTACT_REQUEST_SUCCESS: 'DELETE_CONTACT_REQUEST_SUCCESS',
  DELETE_CONTACT_REQUEST_ERROR: 'DELETE_CONTACT_REQUEST_ERROR',

  EXPORT_CONTACT_REQUEST_ERROR: 'EXPORT_CONTACT_REQUEST_ERROR',
  EXPORT_CONTACT_REQUEST_SUCCESS: 'EXPORT_CONTACT_REQUEST_SUCCESS',
  EXPORT_CONTACT_REQUEST: 'EXPORT_CONTACT_REQUEST',

}

const consultationActionTypes = {
  FETCH_CONSULTATION_REQUEST: 'FETCH_CONSULTATION_REQUEST',
  FETCH_CONSULTATION_REQUEST_SUCCESS: 'FETCH_CONSULTATION_REQUEST_SUCCESS',
  FETCH_CONSULTATION_REQUEST_ERROR: 'FETCH_CONSULTATION_REQUEST_ERROR',

  FETCH_CONSULTATION_BY_ID_REQUEST: 'FETCH_CONSULTATION_BY_ID_REQUEST',
  FETCH_CONSULTATION_BY_ID_REQUEST_SUCCESS: 'FETCH_CONSULTATION_BY_ID_REQUEST_SUCCESS',
  FETCH_CONSULTATION_BY_ID_REQUEST_ERROR: 'FETCH_CONSULTATION_BY_ID_REQUEST_ERROR',

  DELETE_CONSULTATION_REQUEST: 'DELETE_CONSULTATION_REQUEST',
  DELETE_CONSULTATION_REQUEST_SUCCESS: 'DELETE_CONSULTATION_REQUEST_SUCCESS',
  DELETE_CONSULTATION_REQUEST_ERROR: 'DELETE_CONSULTATION_REQUEST_ERROR',

  EXPORT_CONSULTATION_REQUEST_ERROR: 'EXPORT_CONSULTATION_REQUEST_ERROR',
  EXPORT_CONSULTATION_REQUEST_SUCCESS: 'EXPORT_CONSULTATION_REQUEST_SUCCESS',
  EXPORT_CONSULTATION_REQUEST: 'EXPORT_CONSULTATION_REQUEST',
}

const galleryActionTypes = {
  FETCH_GALLERY_REQUEST: 'FETCH_GALLERY_REQUEST',
  FETCH_GALLERY_REQUEST_SUCCESS: 'FETCH_GALLERY_REQUEST_SUCCESS',
  FETCH_GALLERY_REQUEST_ERROR: 'FETCH_GALLERY_REQUEST_ERROR',

  FETCH_GALLERY_BY_ID_REQUEST: 'FETCH_GALLERY_BY_ID_REQUEST',
  FETCH_GALLERY_BY_ID_REQUEST_SUCCESS: 'FETCH_GALLERY_BY_ID_REQUEST_SUCCESS',
  FETCH_GALLERY_BY_ID_REQUEST_ERROR: 'FETCH_GALLERY_BY_ID_REQUEST_ERROR',

  UPLOAD_GALLERY_REQUEST: 'UPLOAD_GALLERY_REQUEST',
  UPLOAD_GALLERY_REQUEST_SUCCESS: 'UPLOAD_GALLERY_REQUEST_SUCCESS',
  UPLOAD_GALLERY_REQUEST_ERROR: 'UPLOAD_GALLERY_REQUEST_ERROR',

  DELETE_GALLERY_REQUEST: 'DELETE_GALLERY_REQUEST',
  DELETE_GALLERY_REQUEST_SUCCESS: 'DELETE_GALLERY_REQUEST_SUCCESS',
  DELETE_GALLERY_REQUEST_ERROR: 'DELETE_GALLERY_REQUEST_ERROR',
}

const newLetterActionTypes = {
  FETCH_NEWS_LETTER_REQUEST: 'FETCH_NEWS_LETTER_REQUEST',
  FETCH_NEWS_LETTER_REQUEST_SUCCESS: 'FETCH_NEWS_LETTER_REQUEST_SUCCESS',
  FETCH_NEWS_LETTER_REQUEST_ERROR: 'FETCH_NEWS_LETTER_REQUEST_ERROR',
  DELETE_NEWS_LETTER_REQUEST: 'DELETE_NEWS_LETTER_REQUEST',
  DELETE_NEWS_LETTER_REQUEST_SUCCESS: 'DELETE_NEWS_LETTER_REQUEST_SUCCESS',
  DELETE_NEWS_LETTER_REQUEST_ERROR: 'DELETE_NEWS_LETTER_REQUEST_ERROR',
  EXPORT_NEWS_LETTER_REQUEST_ERROR: 'EXPORT_NEWS_LETTER_REQUEST_ERROR',
  EXPORT_NEWS_LETTER_REQUEST_SUCCESS: 'EXPORT_NEWS_LETTER_REQUEST_SUCCESS',
  EXPORT_NEWS_LETTER_REQUEST: 'EXPORT_NEWS_LETTER_REQUEST',

  ADD_NEWS_LETTER_REQUEST_SUCCESS: 'ADD_NEWS_LETTER_REQUEST_SUCCESS',
  ADD_NEWS_LETTER_REQUEST_ERROR: 'ADD_NEWS_LETTER_REQUEST_ERROR',
  ADD_NEWS_LETTER_REQUEST: 'ADD_NEWS_LETTER_REQUEST',
}
const offerActionTypes = {
  ADD_OFFER_REQUEST: 'ADD_OFFER_REQUEST',
  ADD_OFFER_REQUEST_SUCCESS: 'ADD_OFFER_REQUEST_SUCCESS',
  ADD_OFFER_REQUEST_ERROR: 'ADD_OFFER_REQUEST_ERROR',

  FETCH_OFFER_REQUEST: 'FETCH_OFFER_REQUEST',
  FETCH_OFFER_REQUEST_SUCCESS: 'FETCH_OFFER_REQUEST_SUCCESS',
  FETCH_OFFER_REQUEST_ERROR: 'FETCH_OFFER_REQUEST_ERROR',

  DELETE_OFFER_REQUEST: 'DELETE_OFFER_REQUEST',
  DELETE_OFFER_REQUEST_SUCCESS: 'DELETE_OFFER_REQUEST_SUCCESS',
  DELETE_OFFER_REQUEST_ERROR: 'DELETE_OFFER_REQUEST_ERROR',

  EXPORT_OFFER_REQUEST_ERROR: 'EXPORT_OFFER_REQUEST_ERROR',
  EXPORT_OFFER_REQUEST_SUCCESS: 'EXPORT_OFFER_REQUEST_SUCCESS',
  EXPORT_OFFER_REQUEST: 'EXPORT_OFFER_REQUEST',
}

const jobExecutionActionTypes = {
  FETCH_JOB_REQUEST: 'FETCH_JOB_REQUEST',
  FETCH_JOB_REQUEST_SUCCESS: 'FETCH_JOB_REQUEST_SUCCESS',
  FETCH_JOB_REQUEST_FAILURE: 'FETCH_JOB_REQUEST_FAILURE',
}
const countActionTypes = {
  FETCH_COUNT_REQUEST: 'FETCH_COUNT_REQUEST',
  FETCH_COUNT_REQUEST_SUCCESS: 'FETCH_COUNT_REQUEST_SUCCESS',
  FETCH_COUNT_REQUEST_FAILURE: 'FETCH_COUNT_REQUEST_FAILURE',

  DELETE_COUNT_REQUEST: 'DELETE_COUNT_REQUEST',
  DELETE_COUNT_REQUEST_SUCCESS: 'DELETE_COUNT_REQUEST_SUCCESS',
  DELETE_COUNT_REQUEST_FAILURE: 'DELETE_COUNT_REQUEST_FAILURE',

  UPDATE_COUNT_REQUEST: 'UPDATE_COUNT_REQUEST',
  UPDATE_COUNT_REQUEST_SUCCESS: 'UPDATE_COUNT_REQUEST_SUCCESS',
  UPDATE_COUNT_REQUEST_FAILURE: 'UPDATE_COUNT_REQUEST_FAILURE',

  EXPORT_COUNT_REQUEST: 'EXPORT_COUNT_REQUEST',
  EXPORT_COUNT_REQUEST_SUCCESS: 'EXPORT_COUNT_REQUEST_SUCCESS',
  EXPORT_COUNT_REQUEST_FAILURE: 'EXPORT_COUNT_REQUEST_FAILURE',
}
const userActionTypes = {
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_REQUEST_SUCCESS: 'FETCH_USER_REQUEST_SUCCESS',
  FETCH_USER_REQUEST_ERROR: 'FETCH_USER_REQUEST_ERROR',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_REQUEST_SUCCESS: 'DELETE_USER_REQUEST_SUCCESS',
  DELETE_USER_REQUEST_ERROR: 'DELETE_USER_REQUEST_ERROR',

  EXPORT_USER_REQUEST_ERROR: 'EXPORT_USER_REQUEST_ERROR',
  EXPORT_USER_REQUEST_SUCCESS: 'EXPORT_USER_REQUEST_SUCCESS',
  EXPORT_USER_REQUEST: 'EXPORT_USER_REQUEST',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_REQUEST_SUCCESS: 'ADD_USER_REQUEST_SUCCESS',
  ADD_USER_REQUEST_ERROR: 'ADD_USER_REQUEST_ERROR',
}
const searchParametersActionTypes = {
  ADD_SEARCH_PARAMETER: 'ADD_SEARCH_PARAMETER',
  CLEAR_SEARCH_PARAMETER: 'CLEAR_SEARCH_PARAMETER',
  UPDATE_SEARCH_PARAMETER: 'UPDATE_SEARCH_PARAMETER',
  GET_FIELD_VALUE: 'GET_FIELD_VALUE',
  SET_FIELD_VALUE: 'SET_FIELD_VALUE'
}
const toasterActionTypes = {
  SHOW_TOASTER: 'SHOW_TOASTER',
  HIDE_TOASTER: 'HIDE_TOASTER',
  RESET_TOASTER: 'RESET_TOASTER',
}
const trackingActionTypes = {
  FETCH_DAILY_VISITOR_REQUEST: 'FETCH_DAILY_VISITOR_REQUEST',
  FETCH_DAILY_VISITOR_SUCCESS: 'FETCH_DAILY_VISITOR_SUCCESS',
  FETCH_DAILY_VISITOR_FAILURE: 'FETCH_DAILY_VISITOR_FAILURE',

  FETCH_PAGE_WISE_VISITOR_REQUEST: 'FETCH_PAGE_WISE_VISITOR_REQUEST',
  FETCH_PAGE_WISE_VISITOR_SUCCESS: 'FETCH_PAGE_WISE_VISITOR_SUCCESS',
  FETCH_PAGE_WISE_VISITOR_FAILURE: 'FETCH_PAGE_WISE_VISITOR_FAILURE',

  FETCH_LINK_CLICKS_REQUEST: 'FETCH_LINK_CLICKS_REQUEST',
  FETCH_LINK_CLICKS_SUCCESS: 'FETCH_LINK_CLICKS_SUCCESS',
  FETCH_LINK_CLICKS_FAILURE: 'FETCH_LINK_CLICKS_FAILURE',

  PAGE_DATA_REQUEST: 'PAGE_DATA_REQUEST',
  PAGE_DATA_SUCCESS: 'PAGE_DATA_SUCCESS',
  PAGE_DATA_FAILURE: 'PAGE_DATA_FAILURE',

}
const modalActionTypes = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL'
}
const fileActionTypes = {
  FETCH_FILE_LIST: 'FETCH_FILE_LIST',
  FETCH_FILE_LIST_ERROR: 'FETCH_FILE_LIST_ERROR',
  FETCH_FILE_LIST_SUCCESS: 'FETCH_FILE_LIST_SUCCESS',

  FETCH_FILE_CONTENTS: 'FETCH_FILE_CONTENTS',
  FETCH_FILE_CONTENTS_SUCCESS: 'FETCH_FILE_CONTENTS_SUCCESS',
  FETCH_FILE_CONTENTS_ERROR: 'FETCH_FILE_CONTENTS_ERROR',

  DELETE_ITEM: 'DELETE_ITEM',
  DELETE_ITEM_SUCCESS: 'DELETE_ITEM_SUCCESS',
  DELETE_ITEM_ERROR: 'DELETE_ITEM_ERROR',
}
const linkClicksActionTypes = {
  FETCH_LINK_CLICK_REQUEST: 'FETCH_LINK_CLICK_REQUEST',
  FETCH_LINK_CLICK_REQUEST_SUCCESS: 'FETCH_LINK_CLICK_REQUEST_SUCCESS',
  FETCH_LINK_CLICK_REQUEST_ERROR: 'FETCH_LINK_CLICK_REQUEST_ERROR',
}
const lookupActionTypes = {
  FETCH_LOOKUP_REQUEST: 'FETCH_LOOKUPS_REQUEST',
  FETCH_LOOKUP_REQUEST_SUCCESS: 'FETCH_LOOKUPS_REQUEST_SUCCESS',
  FETCH_LOOKUP_REQUEST_ERROR: 'FETCH_LOOKUPS_REQUEST_ERROR',

  ADD_LOOKUP_REQUEST: 'ADD_LOOKUPS_REQUEST',
  ADD_LOOKUP_REQUEST_SUCCESS: 'ADD_LOOKUPS_REQUEST_SUCCESS',
  ADD_LOOKUP_REQUEST_ERROR: 'ADD_LOOKUPS_REQUEST_ERROR',

  DELETE_LOOKUP_REQUEST: 'DELETE_LOOKUP_REQUEST',
  DELETE_LOOKUP_REQUEST_SUCCESS: 'DELETE_LOOKUP_REQUEST_SUCCESS',
  DELETE_LOOKUP_REQUEST_ERROR: 'DELETE_LOOKUP_REQUEST_ERROR',

  UPDATE_LOOKUP_REQUEST: 'UPDATE_LOOKUP_REQUEST',
  UPDATE_LOOKUP_REQUEST_SUCCESS: 'UPDATE_LOOKUP_REQUEST_SUCCESS',
  UPDATE_LOOKUP_REQUEST_ERROR: 'UPDATE_LOOKUP_REQUEST_ERROR',
}
const queryExecutorActionTypes = {
  FETCH_QE_REQUEST: 'FETCH_QE_REQUEST',
  FETCH_QE_REQUEST_SUCCESS: 'FETCH_QE_REQUEST_SUCCESS',
  FETCH_QE_REQUEST_ERROR: 'FETCH_QE_REQUEST_ERROR',
}

const rbacActionTypes = {
  ADD_RBAC_REQUEST: 'ADD_RBAC_REQUEST',
  ADD_RBAC_REQUEST_ERROR: 'ADD_RBAC_REQUEST_ERROR',
  ADD_RBAC_REQUEST_SUCCESS: 'ADD_RBAC_REQUEST_SUCCESS',
  
  FETCH_RBAC_REQUEST: 'FETCH_RBAC_REQUEST',
  FETCH_RBAC_REQUEST_ERROR: 'FETCH_RBAC_REQUEST_ERROR',
  FETCH_RBAC_REQUEST_SUCCESS: 'FETCH_RBAC_REQUEST_SUCCESS',


  UPDATE_RBAC_REQUEST: 'UPDATE_RBAC_REQUEST',
  UPDATE_RBAC_REQUEST_ERROR: 'UPDATE_RBAC_REQUEST_ERROR',
  UPDATE_RBAC_REQUEST_SUCCESS: 'UPDATE_RBAC_REQUEST_SUCCESS',
}
export {
  rbacActionTypes,
  queryExecutorActionTypes,
  modalActionTypes,
  adminDashBoardActionType,
  serverErrors,
  loginActions,
  exceptionActionTypes,
  emailActionTypes,
  newLetterActionTypes,
  userActionTypes,
  toasterActionTypes as toastrActionTypes,
  trackingActionTypes,
  commonActionTypes,
  searchParametersActionTypes,
  galleryActionTypes,
  fileActionTypes,
  consultationActionTypes,
  contactActionTypes,
  countActionTypes,
  jobExecutionActionTypes,
  linkClicksActionTypes,
  offerActionTypes,
  lookupActionTypes,
};