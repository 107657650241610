import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { commonActionTypes, linkClicksActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
    ...defaultProperties,
    viewList: []
}

const linkClickReducer = (state = initialState, action) => {
    switch (action.type) {
        case linkClicksActionTypes.FETCH_LINK_CLICK_REQUEST:
            return {
                ...state,
                data: [],
                loading: true
            }
        case linkClicksActionTypes.FETCH_LINK_CLICK_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case linkClicksActionTypes.FETCH_LINK_CLICK_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }

        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        case formCommonSearchFilterAction(REDUCERS.LINK_CLICK):
            return {
                ...state,
                ...action
            }
        default:
            return state
    }
}

export { linkClickReducer }
