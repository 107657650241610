const REDUCERS = {
  EXCEPTION: 'exception',
  OFFERS: 'offer',
  VISITOR: 'visitor',
  WEEKLY_VISITOR: 'weeklyVisitor',
  USER: 'user',
  EMAIL: 'email',
  ACCOUNT: 'account',
  VISITOR_COUNT: 'visitorCount',
  SERVER_ERROR: 'serverError',
  PAGE_WISE_COUNT: 'pageWiseCount',
  NEWS_LETTER: 'newsletter',
  GALLERY: 'gallery',
  PROCESSES: 'processes',
  STATUS: 'status',
  FILE_INFO: 'fileInfo',
  IP_INFO: 'ipInfo',
  LOOKUPS: 'lookups',
  CONTACT: 'contact',
  CONSULT: 'consult',
  QA_REDUCER: 'qeReducer',
  COUNTER: 'counter',
  JOB_EXECUTION: 'jobExecution',
  RBAC: 'rbacData',
  LINK_CLICK: 'linkClickReducer',
  PAGE_VISITED: 'pageVisited',
  COLUMN_DEFINITION: 'TextDefinition',
  LOOKUP: 'lookup',
  SEARCH_PARAM: 'searchParam',
  CHANGE_STATE: 'changeState',
}

export {
  REDUCERS,
}