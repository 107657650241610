const { fileActionTypes, commonActionTypes } = require("../ActionTypes")

const initialState = {
    loading: false,
    list: [],
    error: '',
    currentDirectory: '.',
    contents: ''
}

const fileReducer = (state = initialState, action) => {
    switch (action.type) {
        case fileActionTypes.FETCH_FILE_LIST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case fileActionTypes.FETCH_FILE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                list: action.payload
            }
        case fileActionTypes.FETCH_FILE_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                list: []
            }
        case fileActionTypes.FETCH_FILE_CONTENTS:
            return {
                ...state,
                contents: '',
                loading: true,
            }
        case fileActionTypes.FETCH_FILE_CONTENTS_SUCCESS:
            return {
                ...state,
                contents: action.payload,
                loading: false,
            }
        case fileActionTypes.FETCH_FILE_CONTENTS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}

export { fileReducer }

