const { commonActionTypes, userActionTypes, loginActions } = require("../ActionTypes")

const initialState = {
    loading: false,
    data: {},
    error: ''
}

const columnDefinitionReducer = (state = initialState, action) => {
    switch (action.type) {
        case commonActionTypes.FETCH_COLUMN_DEFINITION_LOOKUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                data: {}
            }
        case commonActionTypes.FETCH_COLUMN_DEFINITION_REQUEST_SUCCESS:
            const objectItems = {}
            action.payload.forEach(x => {
                objectItems[x.label.toLowerCase()] = x.value
            })
            return {
                ...state,
                loading: false,
                data: objectItems,
            }
        case commonActionTypes.FETCH_COLUMN_DEFINITION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: {},
            }
        case loginActions.LOG_OUT_REQUEST:
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}
export {
    columnDefinitionReducer
}