import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { adminDashBoardActionType, commonActionTypes, trackingActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
  loading: false,
  error: '',
  data: [],
}

const visitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashBoardActionType.FETCH_VISITORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        data: [],
      }
    case adminDashBoardActionType.FETCH_VISITORS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: '',
      }
    case adminDashBoardActionType.FETCH_VISITORS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case formCommonSearchFilterAction(REDUCERS.VISITOR):
      return {
        ...state,
        ...action.payload,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

const weeklyVisitorReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashBoardActionType.FETCH_WEEKLY_VISITORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        data: [],
      }
    case adminDashBoardActionType.FETCH_WEEKLY_VISITORS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case adminDashBoardActionType.FETCH_WEEKLY_VISITORS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.WEEKLY_VISITOR):
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const VisitorCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashBoardActionType.FETCH_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        data: [],
        error: '',
        loading: true,
      }
    case adminDashBoardActionType.FETCH_DASHBOARD_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case adminDashBoardActionType.FETCH_DASHBOARD_COUNT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.VISITOR_COUNT):
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const PageVisitorCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashBoardActionType.FETCH_PAGE_WISE_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: '',
      }
    case adminDashBoardActionType.FETCH_PAGE_WISE_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case adminDashBoardActionType.FETCH_PAGE_WISE_COUNT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.PAGE_WISE_COUNT):
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const dailyVisitorInitialState = {
  data: [],
  error: '',
  loading: false,
}

const dailyVisitor = (state = dailyVisitorInitialState, action) => {
  switch (action.type) {
    case trackingActionTypes.FETCH_DAILY_VISITOR_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: '',
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
      }
    case trackingActionTypes.FETCH_DAILY_VISITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case trackingActionTypes.FETCH_DAILY_VISITOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.VISITOR):
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
const pageVisitorInitialState = {
  ...defaultProperties,
  error: '',
}
const PageVisitorData = (state = pageVisitorInitialState, action) => {
  switch (action.type) {
    case trackingActionTypes.PAGE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
      }
    case trackingActionTypes.PAGE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case trackingActionTypes.PAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
export {
  VisitorCountReducer,
  visitorReducer,
  PageVisitorCountReducer,
  weeklyVisitorReducer,
  dailyVisitor,
  PageVisitorData,
}
