import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { rbacActionTypes, commonActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
  ...defaultProperties,
  searchKey: [{ "key": "groupName", "type": "text", "operator": "=", "value": "admin", "lookupAvailable": true }]
}

const rbacReducer = (state = initialState, action) => {
  switch (action.type) {
    case rbacActionTypes.FETCH_RBAC_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case rbacActionTypes.FETCH_RBAC_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case rbacActionTypes.FETCH_RBAC_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case rbacActionTypes.UPDATE_RBAC_REQUEST:
      return {
        ...state,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case rbacActionTypes.UPDATE_RBAC_REQUEST_ERROR:
      return {
        ...state,
        addedItem: null,
        deletedItem: null,
        updatedItem: false,
      }
    case rbacActionTypes.UPDATE_RBAC_REQUEST_SUCCESS:
      const updatedData = [];
      const { totalRows, list } = state.data;
      list.forEach(x => {
        if (x.id === action.payload.id) {
          updatedData.push({
            ...x,
            permissionType: action.payload.permissionType
          })
        } else {
          updatedData.push(x)
        }
      })
      return {
        ...state,
        data: {
          totalRows: totalRows,
          list: updatedData,
        },
        addedItem: null,
        deletedItem: null,
        updatedItem: true,
      }
    case rbacActionTypes.ADD_RBAC_REQUEST:
      return {
        ...state,
        loading: true,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case rbacActionTypes.ADD_RBAC_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedItem: true,
        deletedItem: null,
        updatedItem: null,
      }
    case rbacActionTypes.ADD_RBAC_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        addedItem: false,
        deletedItem: null,
        updatedItem: null,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.RBAC):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { rbacReducer }
