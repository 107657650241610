const { loginActions } = require('../ActionTypes')

const initialState = {
  loading: false,
  firstName: '',
  lastName: '',
  email: '',
  token: '',
  expiresIn: '',
  refreshToken: '',
  errorMessage: '',
  sessionId: '',
  loggedIn: '',
  rbacData: [],
  userType: '',
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActions.AUTHENTICATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: undefined,
        token: '',
        refreshToken: '',
        email: '',
        firstName: '',
        lastName: '',
        rbacData: [],
        userType: '',
        expiresIn: '',
      }
    case loginActions.AUTHENTICATE_USER_SUCCESS:
      const rbacData = {}
      action.payload.permissions.forEach(x => {
        rbacData[x.resourceId] = x.permissionType
      })
      return {
        ...state,
        loading: false,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        token: action.payload.token,
        expiresIn: action.payload.expiresIn,
        refreshToken: action.payload.refreshToken,
        sessionId: action.payload.sessionId,
        loggedIn: true,
        userType: action.payload.userType,
        rbacData: rbacData,
        errorMessage: undefined,
      }
    case loginActions.AUTHENTICATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        rbacData: [],
        firstName: '',
        lastName: '',
        email: '',
        token: '',
        userType: '',
        refreshToken: '',
        sessionId: '',
        loggedIn: false,
        errorMessage: action.payload,
      }
    case loginActions.REFRESH_TOKEN_ERROR:
    case loginActions.LOG_OUT_REQUEST:
      return {
        loading: false,
        firstName: '',
        lastName: '',
        sessionId: '',
        email: '',
        token: null,
        expiresIn: null,
        refreshToken: null,
        errorMessage: null,
        loggedIn: false,
      }
    case loginActions.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        token: null,
        refreshToken: null,
        loading: true,
      }
    case loginActions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        loading: false,
        loggedIn: true,
      }
    default:
      return state
  }
}
export { accountReducer, initialState }
