import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { userActionTypes, commonActionTypes } from '../ActionTypes'
import { addedFailed, addedSuccess, allNull, defaultProperties, deletedFailed, deletedSuccess, updatedFailed } from './defaultProperties'

const initialState = {
  ...defaultProperties,
  userAdded: false,
  userType: 'basic'
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
        message: '',
      }
    case userActionTypes.FETCH_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case userActionTypes.FETCH_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case userActionTypes.DELETE_USER_REQUEST:
    case userActionTypes.ADD_USER_REQUEST:
      return {
        ...state,
        ...allNull,
        loading: true,
        message: '',
      }
    case userActionTypes.DELETE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        ...deletedSuccess,
        loading: false,
        message: action.payload,
      }
    case userActionTypes.DELETE_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload,
        ...deletedFailed
      }
    case userActionTypes.ADD_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        ...addedSuccess,
      }
    case userActionTypes.ADD_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        message: action.payload,
        ...addedFailed,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...allNull,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.USER):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { userReducer }
