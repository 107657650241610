import { formCommonSearchFilterAction, toJSONLocal } from 'src/Utilities'
import { REDUCERS } from '..'
import { jobExecutionActionTypes, commonActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'
const todayDate = new Date();
const initialState = {
    ...defaultProperties,
    viewList: [],
    searchKey: [{ "key": "timeStamp", "type": "date", "operator": "=", "value": `${toJSONLocal(todayDate)}`, "lookupAvailable": false }]
}

const jobExecutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case jobExecutionActionTypes.FETCH_JOB_REQUEST:
            return {
                ...state,
                loading: true,
                orderBy: action.payload.orderBy,
                sortDirection: action.payload.sortDirection,
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                searchKey: action.payload.searchKey,
            }
        case jobExecutionActionTypes.FETCH_JOB_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ''
            }
        case jobExecutionActionTypes.FETCH_JOB_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        case formCommonSearchFilterAction(REDUCERS.JOB_EXECUTION):
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export { jobExecutionReducer }
