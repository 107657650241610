import { galleryActionTypes, commonActionTypes } from '../ActionTypes'

const initialState = {
    loading: false,
    error: '',
    data: [],
    message: '',
    uploaded: false,
    deleted: false,
}

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case galleryActionTypes.FETCH_GALLERY_BY_ID_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case galleryActionTypes.UPLOAD_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
                uploaded: false,
                message: '',
            }
        case galleryActionTypes.FETCH_GALLERY_BY_ID_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case galleryActionTypes.FETCH_GALLERY_BY_ID_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }
        case galleryActionTypes.UPLOAD_GALLERY_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                uploaded: true,
                message: action.payload.message
            }
        case galleryActionTypes.UPLOAD_GALLERY_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                uploaded: false,
                error: action.payload
            }
        case galleryActionTypes.DELETE_GALLERY_REQUEST:
            return {
                ...state,
                deleted: false,
                loading: true,
            }
        case galleryActionTypes.DELETE_GALLERY_REQUEST_SUCCESS:
            return {
                ...state,
                deleted: true,
                loading: false,
                message: action.payload.message
            }
        case galleryActionTypes.DELETE_GALLERY_REQUEST_ERROR:
            return {
                ...state,
                deleted: false,
                loading: false,
                message: action.payload.message
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        default:
            return state
    }
}

export { galleryReducer }
