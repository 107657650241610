import { formCommonSearchFilterAction, toJSONLocal } from 'src/Utilities'
import { REDUCERS } from '..'
import { commonActionTypes, exceptionActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'
const todayDate = new Date();
const initialState = {
  ...defaultProperties,
  searchKey: [{"key":"timeStamp","type":"date","operator":"=","value":`${toJSONLocal(todayDate)}`,"lookupAvailable":false}]
}

const exceptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case exceptionActionTypes.FETCH_EXCEPTION_REQUEST:
      return {
        ...state,
        ...defaultProperties,
        loading: true,
        autoRefresh: state.autoRefresh,
      }
    case exceptionActionTypes.FETCH_EXCEPTION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case exceptionActionTypes.FETCH_EXCEPTION_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
        
      }
    case exceptionActionTypes.DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        loading: true,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case exceptionActionTypes.DELETE_EXCEPTION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedItem: null,
        deletedItem: true,
        updatedItem: null,
        message: action.payload,
      }
    case exceptionActionTypes.DELETE_EXCEPTION_REQUEST_ERROR:
      return {
        ...state,
        addedItem: null,
        deletedItem: false,
        updatedItem: null,
        message: action.payload,
        loading: false,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.EXCEPTION):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { exceptionReducer }
