import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { offerActionTypes, commonActionTypes } from '../ActionTypes'
import { addedFailed, allNull, defaultProperties, deletedFailed, deletedSuccess, addedSuccess } from './defaultProperties'

const initialState = {
  ...defaultProperties,
  searchParameters: [],
}

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case offerActionTypes.FETCH_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        ...defaultProperties
      }
    case offerActionTypes.FETCH_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case offerActionTypes.FETCH_OFFER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case offerActionTypes.DELETE_OFFER_REQUEST:
      return {
        ...state,
        ...allNull,
        loading: true,
      }
    case offerActionTypes.DELETE_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...deletedSuccess,
        message: action.payload,
      }
    case offerActionTypes.DELETE_OFFER_REQUEST_ERROR:
      return {
        ...state,
        ...deletedFailed,
        loading: false,
      }
    case offerActionTypes.ADD_OFFER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        ...addedFailed,
      }
    case offerActionTypes.ADD_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        ...addedSuccess,
        loading: false,
      }
    case offerActionTypes.ADD_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        ...allNull,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.OFFERS):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { offerReducer }
