import CIcon from '@coreui/icons-react'
import { persistStorage, store } from 'src/store'
import { APP_SETTINGS, CONSTANTS, ENVIRONMENT } from './Constants'
import { cilCheckCircle, cilXCircle } from '@coreui/icons'
import CryptoJS from 'crypto-js'
import { commonActionTypes } from 'src/Redux/ActionTypes'
import { REDUCERS } from 'src/Redux'
/**
 * Check weather user is logged in or not
 * @returns boolean
 */

const isLoggedIn = () => {
  return !!sessionStorage.getItem('username')
}
const ResetPersistanceStorage = (key = null) => {
  persistStorage.removeItem(key ?? APP_SETTINGS.PERSISTENT_STORAGE)
}
const ExpandedComponent = ({ data }) => (
  <pre className="">
    <div className="bg-dark text-white p-2">{JSON.stringify(data, null, 2)}</div>
  </pre>
)
/**
 *
 * @param {string} key
 * @param {boolean} typeCheck
 * @returns Is string is null, empty or undefined
 */
const IsNullEmptyOrUndefined = (key, typeCheck) => {
  if (key == undefined || key == null || key == '') {
    return true
  }
  if (typeCheck) {
    if (key === undefined || key === null || key === '') {
      return true
    }
  }
  return false
}
const ObjectToTable = (item) => {
  return (
    <table className='table table-bordered'>
      <thead>
        <tr>
          {Object.keys(item).map((key) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(item).map((key) => (
          <tr></tr>
        ))}
      </tbody>
    </table>
  )
}
/**
 * 
 * @param {number} number 
 * @returns Yes/ No
 */
const NumberToYesOrNo = (number) => {
  if (number == '1' || number == '0') {
    return number == '1'
      ? 'Yes'
      : 'No';
  }
  return number
}
/**
 * 
 * @param {number} number 
 * @returns Icons
 */
const NumberToIcons = (number) => {
  if (number == '1' || number == '0') {
    return number == '1'
      ? <CIcon className='text-success' icon={cilCheckCircle} />
      : <CIcon className='text-danger' icon={cilXCircle} />;
  }
  return number;
}
const FormatDate = (date) => {
  const formattedDate = new Date(date).toLocaleString();
  if (formattedDate === 'Invalid Date') {
    return date;
  }
  return formattedDate;
}
/**
 * 
 * @param {number} min 
 * @param {max} max 
 * @returns 
 */
const RandomNumber = (min, max) => {
  let random = Math.random();
  if (min && max) {
    return parseInt((min + (random * 100000)) % max)
  }
  return parseInt(random * 1000)
}

// Set the encryption key and options
const encryptionKey = 'MySecretKey';
const options = {
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7
};

// Function to encrypt a string
function Encrypt(text) {
  return CryptoJS.AES.encrypt(JSON.stringify(text), '68c41777578469b335248b2e27d0198aea8bf53d').toString();
}

// Function to decrypt a string
function Decrypt(ciphertext) {
  // Decode the ciphertext from Base64 to a WordArray
  const cipherdata = CryptoJS.enc.Base64.parse(ciphertext);

  // Extract the initialization vector and encrypted data from the WordArray
  const iv = cipherdata.clone().shiftBytes(-16);
  const encrypted = CryptoJS.lib.CipherParams.create({
    ciphertext: cipherdata.clone().splice(16)
  });

  // Decrypt the data using the encryption key and options
  const decrypted = CryptoJS.AES.decrypt(encrypted, encryptionKey, options);

  // Return the decrypted data as a string
  return decrypted.toString(CryptoJS.enc.Utf8);
}

class Environment {
  static IsDevelopment() {
    return process.env.NODE_ENV === ENVIRONMENT.DEVELOPMENT;
  }
  static StagingEnvironment() {
    return process.env.NODE_ENV !== ENVIRONMENT.PRODUCTION && !this.IsDevelopment();
  }
  static Name() {
    return process.env.NODE_ENV;
  }
  static GetEnvironmentVariable(key) {
    return process.env[key];
  }
  static GetEnvironmentVariableForDevelopment(key) {
    if (this.IsDevelopment()) {
      return this.GetEnvironmentVariable(key);
    }
    return CONSTANTS.EMPTY;
  }
}
const REGEX = {
  /**
   * string format
   */
  STRING_FORMAT: /\{{\w+}}/g,
}
/**
 * check if string is null, empty or undefined
 * @param {String} key
 * @param {Boolean} typeCheck
 * @returns string is null or empty
 */
const IsNullOrEmpty = (key, typeCheck) => {
  if (typeCheck && !(key instanceof String)) {
    return true;
  }
  return !key;
}
/**
 * format the templated string
 * @param {string} str template string
 * @param  {...any} args objects to be replaced from templated string
 * @returns formatted string
 */
const formatString = (str, args = {}) => {
  let tIndex = 0;
  let objStore = {};
  if (args && Object.keys(args).length !== 0) {
    return str.replace(REGEX.STRING_FORMAT, (match, index) => {
      const key = args[match.substring(2, match.length - 2)];
      if (IsNullOrEmpty(key)) {
        if (IsNullOrEmpty(objStore[match])) {
          objStore[match] = args[tIndex++];
        }
      }
      return IsNullOrEmpty(key) ? objStore[match] : key;
    });
  }
  return str;
};

const ILog = (...args) => {
  if (Environment.IsDevelopment()) {
    console.log(...args, 'SBVishwakarma-log-filter')
  }
}
/**
 *
 * @param {Response} header
 * @returns
 */
function GetFileNameFromResponse(response) {
  const contentDisposition = response.headers["content-disposition"];
  if (!contentDisposition) return "";
  const match = contentDisposition.match(/"(.*?)"/g);
  return match[0].substring(1, match[0].length - 1);
}
const FILE_EXTENSIONS = {
  JPEG: 'jpeg',
  JPG: 'jpg',
  JAVASCRIPT: 'js',
  TYPESCRIPT: 'ts',
  HTML: 'html',
  ZIP: 'zip',
  RAR: 'rar',
  WORD: 'docx',
  TXT: 'txt',
  PDF: 'pdf',
  PNG: 'png',
  EXCEL: 'xlsx',
  EXE: 'exe',
  HTM: 'htm',
  GIF: 'gif',
  JSON: 'json',
  SQL: 'sql',
}

/**
 * Get Text for the given key
 * @param {string} name Key
 * @param {object}} args placeholder values
 * @returns formatted string
 */
const GetTextFromColumnName = (name, args = {}) => {
  const res = store.getState()[REDUCERS.COLUMN_DEFINITION].data[name ? name.toLowerCase() : CONSTANTS.EMPTY] || name;
  if (args) {
    return formatString(res, args)
  }
  return res;
}

/**
 * create an unique global unique identifier string
 * @returns an unique global identifier string
 */
const GUID = function () {
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xyxyxx-xxyx-xxyyxyxx-xxyyyxy-xyyyxyxyy".replace(/[xy]/g, function (c) {
    let r = Math.random() * 36; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 36 | 0;
      d = Math.floor(d / 36);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 36 | 0;
      d2 = Math.floor(d2 / 36);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(36);
  });
};

const formCommonSearchFilterAction = (reducerName) => {
  return `${commonActionTypes.UPDATE_FILTERS}${reducerName}`;
}
const formCommonFilterAction = (reducerName) => {
  return `${commonActionTypes.UPDATE_AUTO_REFRESH}${reducerName}`;
}
function toJSONLocal(date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
export {
  Environment,
  FILE_EXTENSIONS,
  toJSONLocal,
  formCommonFilterAction,
  ILog,
  isLoggedIn,
  GUID,
  ExpandedComponent,
  ResetPersistanceStorage,
  IsNullEmptyOrUndefined,
  ObjectToTable,
  NumberToYesOrNo,
  NumberToIcons,
  RandomNumber,
  GetTextFromColumnName,
  FormatDate,
  Encrypt,
  Decrypt,
  formCommonSearchFilterAction,
  IsNullOrEmpty,
  REGEX,
  formatString,
  GetFileNameFromResponse,
}
