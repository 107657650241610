import { commonActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
 ...defaultProperties
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.EXPORT_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
      }
    case commonActionTypes.EXPORT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload,
      }
    case commonActionTypes.EXPORT_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    default:
      return state
  }
}

/**
 * Process list reducer
 * @param {Object} state 
 * @param {Function} action 
 * @returns 
 */
const processListReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.LIST_PROCESS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        data: action.payload
      }
    case commonActionTypes.LIST_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: '',
      }
    case commonActionTypes.LIST_PROCESS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.KILL_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case commonActionTypes.KILL_PROCESS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        killedProcess: true,
      }
    case commonActionTypes.KILL_PROCESS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        killedProcess: false,
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

/**
 * status list reducer
 * @param {Object} state 
 * @param {Function} action 
 * @returns 
 */
const StatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.FETCH_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ''
      }
    case commonActionTypes.FETCH_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        data: [],
      }
    case commonActionTypes.FETCH_STATUS_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState
      }
    default:
      return state
  }
}

const initialLookupState = {
  lookupList: [],
  loading: false,
  error: ''
}
const lookupReducer = (state = initialLookupState, action) => {
  switch (action.type) {
    case commonActionTypes.FETCH_LOOKUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        lookupList: []
      }
    case commonActionTypes.FETCH_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        lookupList: action.payload,
        error: ''
      }
    case commonActionTypes.FETCH_LOOKUP_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        lookupList: []
      }
    default:
      return state
  }
}

export { commonReducer, processListReducer, StatusReducer, lookupReducer }
