import { APP_SETTINGS } from "src/Utilities/Constants"

/**
 * default properties can be used in all reducers
 */
export const defaultProperties = {
    loading: false,
    addedItem: null,
    deletedItem: null,
    updatedItem: null,
    orderBy: APP_SETTINGS.DEFAULT_SORT_FIELD,
    sortDirection: APP_SETTINGS.DEFAULT_SORT_DIRECTION,
    pageIndex: APP_SETTINGS.DEFAULT_PAGE_INDEX,
    pageSize: APP_SETTINGS.DEFAULT_PAGE_SIZE,
    searchKey: [],
    error: '',
    data: [],
    autoRefresh: false,
    autoRefreshTime: 3000,
    message: '',
}

const allNull = {
    addedItem: null,
    updatedItem: null,
    deletedItem: null,
}
const addedSuccess = {
    addedItem: true,
    updatedItem: null,
    deletedItem: null,
}

const addedFailed = {
    addedItem: false,
    updatedItem: null,
    deletedItem: null,
}

const updatedSuccess = {
    addedItem: null,
    updatedItem: true,
    deletedItem: null,
}
const updatedFailed = {
    addedItem: null,
    updatedItem: false,
    deletedItem: null,
}
const deletedSuccess = {
    addedItem: null,
    updatedItem: null,
    deletedItem: true,
}
const deletedFailed = {
    addedItem: null,
    updatedItem: null,
    deletedItem: false,
}

export {
    addedFailed,
    addedSuccess,
    deletedFailed,
    deletedSuccess,
    updatedFailed,
    updatedSuccess,
    allNull
}