import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { commonActionTypes, countActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
    ...defaultProperties,
    viewList: []
}

const countReducer = (state = initialState, action) => {
    switch (action.type) {
        case countActionTypes.FETCH_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
                orderBy: action.payload.orderBy,
                sortDirection: action.payload.sortDirection,
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                searchKey: action.payload.searchKey,
                addedItem: null,
                deletedItem: null,
                updatedItem: null,
            }
        case countActionTypes.FETCH_COUNT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: '',
            }
        case countActionTypes.FETCH_COUNT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }
        case countActionTypes.DELETE_COUNT_REQUEST:
            return {
                ...state,
                addedItem: null,
                deletedItem: null,
                updatedItem: null,
                loading: true,
            }
        case countActionTypes.DELETE_COUNT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                addedItem: null,
                deletedItem: true,
                updatedItem: null,
                message: action.payload,
            }
        case countActionTypes.DELETE_COUNT_REQUEST_FAILURE:
            return {
                ...state,
                addedItem: null,
                deletedItem: false,
                updatedItem: null,
                loading: false,
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        case formCommonSearchFilterAction(REDUCERS.COUNTER):
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export { countReducer }
