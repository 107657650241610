import { RecordHelper } from './CreateItem'
import { APP_SETTINGS } from './Constants'

class RequestContext {
  doNotEncrypt = false;
  /**
   * @param {URLSearchParams} QueryString parameters
   */
  queryParameters
  /**
   * @param {Object} Request Body
   */
  requestBody
  /**
   * @param {string} relative url
   */
  url
  /**
   * @param {HTTP_METHOD} Http Method
   */
  httpMethod
  /**
   * @param {Array} request header
   */
  headers = [];

  /**
   *
   * @param {string} url relative URL
   * @param {HTTP_METHOD} httpMethod http method GET/POST/PUT/DELETE/PATCH
   * @param {Object} queryParameters query parameters
   * @param {Object} requestBody request body
   */
  constructor(url, httpMethod, queryParameters = undefined, requestBody = undefined) {
    this.httpMethod = httpMethod
    this.queryParameters = queryParameters
    this.requestBody = requestBody
    this.url = url
  }
}

class PaginationContext {
  /**
   * @param {number} page
   */
  pageIndex
  /**
   * @param {string} order by field
   */
  orderBy
  /**
   * @param {string} sorting order ASC/ DESC
   */
  sortDirection
  /**
   * @param {number} number of items to show
   */
  pageSize

  /**
   * @param {string} keyword to search for
   */
  searchKey
  /**
   * @param {SearchParameters} searchParameter
   */
  searchParameter

  constructor(pageIndex, pageSize, sortDirection, orderBy, searchKey, searchParameter) {
    this.pageIndex = pageIndex ?? APP_SETTINGS.DEFAULT_PAGE_INDEX
    this.pageSize = pageSize ?? APP_SETTINGS.DEFAULT_PAGE_SIZE
    this.sortDirection = sortDirection ?? APP_SETTINGS.DEFAULT_SORT_DIRECTION
    this.orderBy = orderBy ?? APP_SETTINGS.DEFAULT_SORT_FIELD
    this.searchKey = searchKey ?? null
    this.searchParameter = searchParameter ?? null
  }
}

class SearchParameters {
  key;
  operator;
  identity;
  values;
  /**
   *
   * @param {string} key column name to search for
   * @param {Array} values values to search for
   * @param {string} operator
   * @param {Array} values}
   */
  constructor(key, values, operator, identityKey) {
      this.key = key;
      this.values = values;
      this.operator = operator;
      this.identityKey = identityKey;
  }

  toString() {
    return JSON.stringify(this)
  }
}
export { RequestContext, PaginationContext, SearchParameters, RecordHelper  }
