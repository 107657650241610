const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  EXPORT: 'EXPORT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}
const COLUMN_CONSTANTS = {
  TIME_STAMP: 'timestamp',
}
const DATA_TYPE = {
  TEXT: 'text',
  DATE: 'date',
  NUMBER: 'number',
  EMAIL: 'email',
  YES_NO: 'YES_NO'
}
const APP_SETTINGS = {
  DEFAULT_PAGE_INDEX: 1,
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_SORT_DIRECTION: 'DESC',
  DEFAULT_SORT_FIELD: 'timeStamp',
  PERSISTENT_STORAGE: 'persist:root',
}
const API_ENDPOINTS = {
  JOBS: 'jobs',
  OFFERS: 'offers',
  COUNTS: {
    PAGE_WISE: 'visitos/page-wise',
    DASHBOARD: 'Dashboard/counts',
  },
  QE: 'database/query',
  CONTACT: 'contact',
  CONSULT: 'consult',
  COUNTER: 'counter',
  ACCOUNT: {
    LOGIN: 'login',
    LOGOUT: 'logout',
    REFRESH_TOKEN: 'refresh-token',
  },
  EXCEPTIONS: 'Exceptions',
  EMAILS: 'email',
  EMAIL_BY_ID: 'email/{{id}}/view',
  RESEND_EMAIL_BY_ID: 'email/{{id}}/resend',
  USER: 'user',
  NEWS_LETTER: 'Email/subscribe',
  IMAGE: 'image',
  LOOKUPS: 'lookup/list',
  LOOKUP: 'lookup',
  APPLICATION: {
    LINK_CLICKS: 'visitors/link-clicks',
    LIST_PROCESSES: 'application/process',
    LIST_STATUS: 'application/status',
    FILE_INFO: 'application/files',
    PAGED_DATA: 'visitors/page-data',
    FILE_VIEW: 'application/file/view'
  }
}
const RESOURCE_ID = {
  ALLOW_ANONYMOUS: 0,
  ALL: 0,
  LOGIN: -1,
  ERROR: {
    LIST: 1,
    DELETE: 2,
    EXPORT: 3,
  },
  CRON_JOBS: {
    LIST: 4,
    DELETE: 5,
    EXPORT: 6,
  },
  CONTACT: {
    LIST: 7,
    DELETE: 8,
    EXPORT: 9,
    UPDATE: 10
  },
  CONSULTATION: {
    LIST: 11,
    DELETE: 12,
    EXPORT: 13,
    UPDATE: 14
  },
  EXECUTE_QUERY: {
    LIST: 15
  },
  USER: {
    LIST: 19,
    ADD: 20,
    EXPORT: 21,
    DELETE: 22,
  },
  GALLERY: {
    LIST: 23,
    ADD: 24,
    UPDATE: 25,
    DELETE: 26
  },
  EMAIL: {
    LIST: 27,
    SEND: 59,
    EXPORT: 28,
    UPDATE: 29,
    DELETE: 30,
    VIEW: 31,
    RESEND: 32
  },
  EXPLORER: {
    LIST: 33,
    ADD: 34,
    UPDATE: 35,
    VIEW: 36
  },
  COUNTER: {
    LIST: 37,
    ADD: 38,
    UPDATE: 39
  },
  RBAC: {
    LIST: 40,
    ADD: 41,
    UPDATE: 42,
    EXPORT: 43,
  },
  DATABASE: {
    EXECUTE_QUERY: 44,
    PROCESS_LIST: 45,
    STATUS: 46,
    KILL_PROCESS: 47,
  }, TRACKING: {
    DAILY_VISITOR: {
      LIST: 48,
      EXPORT: 49,
    },
    PAGE_WISE: {
      LIST: 50,
      EXPORT: 51,
    },
    LINK_CLICKS:
    {
      LIST: 52,
      EXPORT: 53,
    }
  },
  NEWS_LETTER: {
    LIST: 54,
    EXPORT: 55,
    UPDATE: 56,
    ADD: 57,
    DELETE: 58,
  }
}

const securedResource = {
  CONSULTATION_DELETE: 'CONSULTATION_DELETE',
  CONSULTATION_EXPORT: 'CONSULTATION_EXPORT',
  CONSULTATION_PAGE: 'CONSULTATION_PAGE',
  CONSULTATION_UPDATE: 'CONSULTATION_UPDATE',
  CONTACT_DELETE: 'CONTACT_DELETE',
  CONTACT_EXPORT: 'CONTACT_EXPORT',
  CONTACT_PAGE: 'CONTACT_PAGE',
  CONTACT_UPDATE: 'CONTACT_UPDATE',
  COUNTER_ADD: 'COUNTER_ADD',
  COUNTER_DELETE: 'COUNTER_DELETE',
  COUNTER_PAGE: 'COUNTER_PAGE',
  COUNTER_UPDATE: 'COUNTER_UPDATE',
  DAILY_VISITORS_EXPORT: 'DAILY_VISITORS_EXPORT',
  DAILY_VISITORS_PAGE: 'DAILY_VISITORS_PAGE',
  DATABASE_DELETE: 'DATABASE_DELETE',
  DATABASE_PROCESS: 'DATABASE_PROCESS',
  DATABASE_STATUS: 'DATABASE_STATUS',
  DATABASE_VIEW: 'DATABASE_VIEW',
  EMAIL_DELETE: 'EMAIL_DELETE',
  EMAIL_EXPORT: 'EMAIL_EXPORT',
  EMAIL_PAGE: 'EMAIL_PAGE',
  EMAIL_RESEND: 'EMAIL_RESEND',
  EMAIL_SEND: 'EMAIL_SEND',
  EMAIL_UPDATE: 'EMAIL_UPDATE',
  EMAIL_VIEW: 'EMAIL_VIEW',
  EXCEPTION_DELETE: 'EXCEPTION_DELETE',
  EXCEPTION_EXPORT: 'EXCEPTION_EXPORT',
  EXCEPTION_PAGE: 'EXCEPTION_PAGE',
  EXPLORER_ADD: 'EXPLORER_ADD',
  EXPLORER_PAGE: 'EXPLORER_PAGE',
  EXPLORER_UPDATE: 'EXPLORER_UPDATE',
  EXPLORER_VIEW: 'EXPLORER_VIEW',
  GALLERY_ADD: 'GALLERY_ADD',
  GALLERY_DELETE: 'GALLERY_DELETE',
  GALLERY_PAGE: 'GALLERY_PAGE',
  GALLERY_UPDATE: 'GALLERY_UPDATE',
  JOBS_DELETE: 'JOBS_DELETE',
  JOBS_EXPORT: 'JOBS_EXPORT',
  JOBS_PAGE: 'JOBS_PAGE',
  LINK_CLICKS_EXPORT: 'LINK_CLICKS_EXPORT',
  LINK_CLICKS_PAGE: 'LINK_CLICKS_PAGE',
  NEWS_LETTER_PAGE: 'NEWS_LETTER_PAGE',
  NEWS_LETTER_ADD: 'NEWS_LETTER_ADD',
  NEWS_LETTER_DELETE: 'NEWS_LETTER_DELETE',
  NEWS_LETTER_EXPORT: 'NEWS_LETTER_EXPORT',
  NEWS_LETTER_UPDATE: 'NEWS_LETTER_UPDATE',
  OFFERS_ADD: 'OFFERS_ADD',
  OFFERS_DELETE: 'OFFERS_DELETE',
  OFFERS_PAGE: 'OFFERS_PAGE',
  OFFERS_UPDATE: 'OFFERS_UPDATE',
  PAGE_WISE_EXPORT: 'PAGE_WISE_EXPORT',
  PAGE_WISE_PAGE: 'PAGE_WISE_PAGE',
  QUERY_DELETE: 'QUERY_DELETE',
  QUERY_INSERT: 'QUERY_INSERT',
  QUERY_PAGE: 'QUERY_PAGE',
  QUERY_UPDATE: 'QUERY_UPDATE',
  RBAC_ADD: 'RBAC_ADD',
  RBAC_EXPORT: 'RBAC_EXPORT',
  RBAC_PAGE: 'RBAC_PAGE',
  RBAC_UPDATE: 'RBAC_UPDATE',
  USER_ADD: 'USER_ADD',
  USER_DELETE: 'USER_DELETE',
  USER_PAGE: 'USER_PAGE',
  USER_UPDATE: 'USER_UPDATE',
}

const inputTypes = {
  TEXT: 'text',
  DROP_DOWN: 'select',
  NUMBER: 'number',
  EMAIL: 'email',
  DATE: 'date',
  PASSWORD: 'password',
  DATE_TIME_LOCAL: 'date-time-local',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  SUBMIT: 'submit',
  BUTTON: 'button',
}
/**
 * Sql operators for dropdown
 */
const SqlOperators = {
  EQUAL: '=',
  NOT_EQUAL: '<>',
  LIKE: 'LIKE',
  NOT_LIKE: 'NOT LIKE',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_EQUAL_TO: '>=',
  LESS_THAN_EQUAL_TO: '<=',
  IS_NULL: 'IS NULL',
  IS_NOT_NULL: 'IS NOT NULL',
  BETWEEN: 'BETWEEN',
  NOT_BETWEEN: 'NOT BETWEEN',
  IN: 'IN',
  NOT_IN: 'NOT IN',
}

const _operators = {
  EQUAL_TO: { label: 'Equals to', value: SqlOperators.EQUAL },
  NOT_EQUAL_TO: { label: 'Not Equals to', value: SqlOperators.NOT_EQUAL },
  LIKE: { label: 'Like', value: SqlOperators.LIKE },
  NOT_LIKE: { label: 'Not Like', value: SqlOperators.NOT_LIKE },
  NULL: { label: 'Is Null', value: SqlOperators.IS_NULL },
  NOT_NULL: { label: 'Is Not Null', value: SqlOperators.IS_NOT_NULL },
  SELECT: { label: 'Select...', value: -1 },
  GREATER_THAN: { label: 'Greater Than', value: SqlOperators.GREATER_THAN },
  GREATER_THAN_OR_EQUAL: { label: 'Greater Than Or Equals To', value: SqlOperators.GREATER_THAN_EQUAL_TO },
  LESS_THAN: { label: 'Less Than', value: SqlOperators.LESS_THAN },
  LESS_THAN_EQUAL_TO: { label: 'Less Than Or Equals To ', value: SqlOperators.LESS_THAN_EQUAL_TO },
  BETWEEN: { label: 'Between', value: SqlOperators.BETWEEN },
  NOT_BETWEEN: { label: 'Not Between', value: SqlOperators.NOT_BETWEEN },
  IN: { label: 'In', value: SqlOperators.IN },
  NOT_IN: { label: 'Not In', value: SqlOperators.NOT_IN },
}

const OperatorTypes = {
  text: [
    _operators.SELECT, _operators.EQUAL_TO,
    _operators.NOT_EQUAL_TO,
    _operators.LIKE, _operators.NOT_LIKE, _operators.NULL, _operators.NOT_NULL
  ],
  number: [
    _operators.SELECT,
    _operators.EQUAL_TO,
    _operators.NOT_EQUAL_TO,
    _operators.GREATER_THAN,
    _operators.GREATER_THAN_OR_EQUAL,
    _operators.LESS_THAN,
    _operators.LESS_THAN_EQUAL_TO,
    _operators.NULL,
    _operators.NOT_NULL,
    _operators.BETWEEN,
    _operators.NOT_BETWEEN,
    _operators.IN,
    _operators.NOT_IN,
  ],
  date: [
    _operators.SELECT,
    _operators.EQUAL_TO,
    _operators.NOT_EQUAL_TO,
    _operators.GREATER_THAN,
    _operators.LESS_THAN,
    _operators.GREATER_THAN_OR_EQUAL,
    _operators.LESS_THAN_EQUAL_TO,
    _operators.LIKE,
    _operators.NOT_LIKE,
    _operators.BETWEEN,
    _operators.NULL,
    _operators.NOT_NULL,
    _operators.IN,
    _operators.NOT_IN,
  ],
}
const COLUMN_TYPE = {
  ACTION: 'ACTION',
  DEFAULT: 'DEFAULT',
}
const LOOKUP_TYPE = {
  ENVIRONMENT: 'environment',
  LOG_TYPE: 'logType',
  STATUS: 'status',
  EMAIL_TYPE: 'emailType',
}
const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  QA: 'qa',
  SIT: 'sit',
}
const PAGE_INDEXES = [10, 25, 50, 100, 500]
const CONSTANTS = {
  NULL: null,
  UNDEFINED: undefined,
  EMPTY: '',
  ASTERISK: '*',
  HEADER: {
    X_INFO: 'x-info',
    ACCESS_CONTROL: 'Access-Control-Expose-Headers',
    ALLOW_ORIGIN: 'Access-Control-Allow-Origin',
    ALLOW_HEADER: 'Access-Control-Allow-Header',
    UID: 'uid',
    REQUEST_ID: 'request-Id',
  }

}
export {
  CONSTANTS,
  inputTypes,
  HTTP_METHOD,
  API_ENDPOINTS,
  APP_SETTINGS,
  DATA_TYPE,
  OperatorTypes,
  SqlOperators,
  PAGE_INDEXES,
  ENVIRONMENT,
  COLUMN_TYPE,
  LOOKUP_TYPE,
  RESOURCE_ID,
  securedResource,
}
