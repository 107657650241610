import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { commonActionTypes, consultationActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'

const initialState = {
    ...defaultProperties,
    viewList: []
}

const consultReducer = (state = initialState, action) => {
    switch (action.type) {
        case consultationActionTypes.FETCH_CONSULTATION_REQUEST:
            return {
                ...state,
                loading: true,
                orderBy: action.payload.orderBy,
                sortDirection: action.payload.sortDirection,
                pageIndex: action.payload.pageIndex,
                pageSize: action.payload.pageSize,
                searchKey: action.payload.searchKey,
            }
        case consultationActionTypes.FETCH_CONSULTATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            }
        case consultationActionTypes.FETCH_CONSULTATION_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: [],
            }
        case consultationActionTypes.DELETE_CONSULTATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case consultationActionTypes.DELETE_CONSULTATION_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload,
            }
        case consultationActionTypes.DELETE_CONSULTATION_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
            }
        case consultationActionTypes.FETCH_CONSULTATION_BY_ID_REQUEST:
            return {
               ...state,
                loading: true,
                viewList: {},
            }
        case consultationActionTypes.FETCH_CONSULTATION_BY_ID_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                viewList: action.payload,
            }
        case consultationActionTypes.FETCH_CONSULTATION_BY_ID_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState,
            }
        case formCommonSearchFilterAction(REDUCERS.CONSULT):
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export { consultReducer }
