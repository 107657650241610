class Field{
    type;
    name;
    placeHolder;
    required;

}
/**
 * Generic class to define the fields to create a new record for different screens.
 */
class RecordHelper{
    #fields = [];
    /**
     * 
     * @param {Field} param0 
     */
    AddField({
        name,
        type,
        placeHolder,
        required,
    }){
        this.#fields.push({
            name,
            type,
            placeHolder,
            required
        })
    }
}
export {RecordHelper}