const { queryExecutorActionTypes, commonActionTypes } = require("../ActionTypes")

const initialState = {
    loading: false,
    data: [],
    error: ''
}


const qeReducer = (state = initialState, action) => {
    switch (action.type) {
        case queryExecutorActionTypes.FETCH_QE_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                data: []
            }
        case queryExecutorActionTypes.FETCH_QE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                data: action.payload
            }
        case queryExecutorActionTypes.FETCH_QE_REQUEST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            }
        case commonActionTypes.RESET_REDUCERS:
            return {
                ...state,
                ...initialState
            }
        default:
            return state
    }
}
export { qeReducer }