const { serverErrors, commonActionTypes } = require('../ActionTypes')

const initialState = {
  errorCode: '',
  errorMessage: '',
}

const serverErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case serverErrors.GET_SERVER_ERROR:
      return {
        ...state,
      }
    case serverErrors.SET_SERVER_ERROR:
      return {
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
      }
    case serverErrors.RESET_SERVER_ERROR:
      return {
        errorCode: '',
        errorMessage: '',
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
export { serverErrorReducer }
