import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { lookupActionTypes, commonActionTypes } from '../ActionTypes'
import { defaultProperties, addedFailed, allNull, addedSuccess, deletedFailed, deletedSuccess, updatedSuccess, updatedFailed } from './defaultProperties'

const initialState = {
  ...defaultProperties,
}

const lookupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case lookupActionTypes.FETCH_LOOKUP_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
        ...allNull
      }
    case lookupActionTypes.FETCH_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case lookupActionTypes.FETCH_LOOKUP_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case lookupActionTypes.ADD_LOOKUP_REQUEST_ERROR:
      return {
        ...state,
        ...addedFailed
      }
    case lookupActionTypes.ADD_LOOKUP_REQUEST:
    case lookupActionTypes.DELETE_LOOKUP_REQUEST:
    case lookupActionTypes.UPDATE_LOOKUP_REQUEST:
      return {
        ...state,
        ...allNull
      }
    case lookupActionTypes.ADD_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        ...addedSuccess
      }
    case lookupActionTypes.DELETE_LOOKUP_REQUEST_ERROR:
      return {
        ...state,
        ...deletedFailed
      }
    case lookupActionTypes.DELETE_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        ...deletedSuccess
      }
    case lookupActionTypes.UPDATE_LOOKUP_REQUEST_ERROR:
      return {
        ...state,
        ...updatedFailed
      }
    case lookupActionTypes.UPDATE_LOOKUP_REQUEST_SUCCESS:
      return {
        ...state,
        ...updatedSuccess
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.LOOKUP):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { lookupsReducer }
