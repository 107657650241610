import { formCommonSearchFilterAction } from 'src/Utilities'
import { REDUCERS } from '..'
import { commonActionTypes, emailActionTypes } from '../ActionTypes'
import { defaultProperties } from './defaultProperties'
const initialState = {
  ...defaultProperties,
  viewList: [],
  resentSuccess: null,
}

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case emailActionTypes.FETCH_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        orderBy: action.payload.orderBy,
        sortDirection: action.payload.sortDirection,
        pageIndex: action.payload.pageIndex,
        pageSize: action.payload.pageSize,
        searchKey: action.payload.searchKey,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case emailActionTypes.FETCH_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case emailActionTypes.ADD_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedItem: true,
        deletedItem: null,
        updatedItem: null,
        message: action.payload
      }
    case emailActionTypes.ADD_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        addedItem: false,
        deletedItem: null,
        updatedItem: null,
      }
    case emailActionTypes.FETCH_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
      }
    case emailActionTypes.ADD_EMAIL_REQUEST:
    case emailActionTypes.DELETE_EMAIL_REQUEST:
    case emailActionTypes.FETCH_EMAIL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        addedItem: null,
        deletedItem: null,
        updatedItem: null,
      }
    case emailActionTypes.DELETE_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedItem: null,
        deletedItem: true,
        message: action.payload,
        updatedItem: null,
      }
    case emailActionTypes.DELETE_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        addedItem: null,
        deletedItem: false,
        updatedItem: null,
        loading: false,
      }
    case emailActionTypes.FETCH_EMAIL_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        viewList: action.payload,
        loading: false,
      }
    case emailActionTypes.FETCH_EMAIL_BY_ID_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case emailActionTypes.RESEND_EMAIL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        resentSuccess: ''
      }
    case emailActionTypes.RESEND_EMAIL_BY_ID_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        resentSuccess: 'true',
        message: action.payload,
      }
    case emailActionTypes.RESEND_EMAIL_BY_ID_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        resentSuccess: 'false'
      }
    case commonActionTypes.RESET_REDUCERS:
      return {
        ...state,
        ...initialState,
      }
    case formCommonSearchFilterAction(REDUCERS.EMAIL):
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export { emailReducer }
